.estilo_campos{
    color:#0d3159;
}

.input_campo {
    background-color: rgb(240,240,240) !important;
}

.contenedor_registro_{
    background-color:white !important;
}
.temario{
    background-color: #7ce6e0;
    color:#0d3159;
    padding: 10px 20px;
    font-weight: 900;
    font-size: 18px;
    border-radius: 25px;
}
.temario:hover{
    color:#0d3159;
}
.texto_registro_info{
    text-align: justify;
    font-size: 15px;
}