/* For demo */


.principal{
  /*background-image: url('../images/01_impacDemo_home/01-banner-principal-provicional.jpg');*/
  border-image-repeat: no-repeat;
  
  background-size: cover;
}
.titulo{
  color: #ffffff;
  font-size: 50px;
  line-height: 1;
  margin-top: 0%;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px!important;
}
.fecha{
  color: #eb4b1c;
  font-size: 45px;
  line-height: 1;
  font-weight: bold;
}
.numero{
  color: #ffffff;
  font-size: 70px;
}
.fecha{
  color: #ffffff;
  font-size: 25px;
}
.contador{
  line-height: 1;
  border-right: 4px solid #eb4b1c;
}
.contadorf{
  line-height: 1;
}
.subtitle{
  color: #ffffff;
  font-size: 24px;
  line-height: 1;
  margin-top: 3%;
}
.ponentes1{
  background-image: url('../images/01_impacDemo_home/02-ponente1.png');
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
}
.ponentes2{
  background-image: url('../images/01_impacDemo_home/02-ponente2.png');
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
}
.ponente{
  width: 100%;
}
.programa{
  /*background-image: url('../images/01_impacDemo_home/03-fondo-programa.jpg');
  border-image-repeat: no-repeat;
  background-size: cover;*/
}
.divPrograma{
  background-color: #ffffff;
  text-align: left;
  line-height: 1;
}
.tituPrograma{
  color:#ffffff;
  margin-bottom: 0px;
  border-bottom: 4px solid #ffffff;
  margin: 3% 0%;
  font-size: 3em;
}
.visualizar{
  border-bottom: 1px solid #000000;
}
.info-hora{
  color:#000000;
  font-size: 1.3em;
  font-family: 'Economica', sans-serif;
  font-weight: bold;
}
.info-fecha{
  color:#000000;
  font-size: 1.3em;
  font-family: 'Economica', sans-serif;
}
.info-nombre{
  color:#000000;
  font-size: 1.6em;
}
/*font-family: 'Montserrat', sans-serif;*/
.info-descripcion{
  color:#01c7e2;
  font-size: 0.8em;
  /*font-family: 'Montserrat', sans-serif;*/
}
.info-video{
  color:#000000;
}
.info-horario{
  margin: 15px;
}
.entradas{
  background-image: url('../images/01_impacDemo_home/04-fondo.jpg');
  border-image-repeat: no-repeat;
  background-size: cover;
}
.divEntradas{
  text-align: right;
  line-height: 1;
}
.tituEntradas{
  color:#eb4b1c;
  margin: 3% 0% 0% 0%;
  font-size: 3em;
}
.textEntradas{
  color:#000000;
  font-size: 1.5em;
}
.tituLogos{
  border-bottom: 4px solid #000000;
  font-size: 3em;
  text-align: left;
  color: #000000;
}
@media only screen and (max-width: 990px) {
  .principal{
    height: 600px!important;
  }
}
@media only screen and (max-width: 600px) {
  .principal{
    height: 500px!important;
  }
}

.header_subrayado{
  text-decoration: underline red;
}

.contenedor_link_movil{
  margin: 5px 0px;
}

.contenedor_menu_row{
  position:absolute;
  z-index:99;
  background-color:white;
  width:100%;
}

.contenedor_menu_col{
  position:relative;
  background-color:white;
}