/* For demo */

.titles{
  text-decoration: none;
  color: #0d3159;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;;
}

.titles_subrayado{
  text-decoration: none;
  color: #0d3159;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration-line: underline;
  text-decoration-color: red;
}
.barra_superior{
    position: absolute!important;
    z-index: 99!important;
    width: 100%;
    left: 0px;
}
.principal{
  /*background-image: url('../../images/eventos/01-ingenierIa-vehicular/01-banner-home.jpg');*/
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
  background-color: #ffffff;
}
.titulo{
  color: #ffffff;
  font-size: 45px;
  line-height: 1.4;
  font-weight: bold;
  font-family:  'Bebas Neue';
}
.fecha-top{
  color: #f4b414;
  font-size: 40px;
  line-height: 1;
  font-family:  'Bebas Neue';
}
.numero{
  color: #000000;
  font-size: 70px;
}
.fecha{
  color: #000000;
  font-size: 25px;
}
.contador{
  line-height: 1;
  border-right: 4px solid #f4b414;
}
.contadorf{
  line-height: 1;
}
.subtitle{
  color: #ffffff;
  font-size: 24px;
  line-height: 1;
  margin-top: 3%;
}
.ponentes1{
  background-image: url('../../images/01_impacDemo_home/02-ponente1.png');
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
}
.ponentes2{
  background-image: url('../../images/01_impacDemo_home/02-ponente2.png');
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
}
.ponente{
  width: 100%;
}
.programa{
  /*background-image: url('../../images/eventos/01-ingenierIa-vehicular/03-fondo.jpg');
  border-image-repeat: no-repeat;
  background-size: cover;*/
  background-color: #0d3159;
}
.divPrograma{
  background-color: #ffffff;
  text-align: left;
  line-height: 1;
}
.tituPrograma{
  color:#ffffff;
  margin-bottom: 0px;
  border-bottom: 4px solid #ffffff;
  margin: 3% 0%;
  font-size: 3em;
}
.visualizar{
  border-bottom: 1px solid #000000;
}
.info-hora{
  color:#000000;
  font-size: 1.3em;
  font-family: 'Economica', sans-serif;
  font-weight: bold;
}
.info-fecha{
  color:#000000;
  font-size: 1.3em;
  font-family: 'Economica', sans-serif;
}
.info-nombre{
  color:#000000;
  font-size: 1.3em;
  text-transform: uppercase;
}
/*font-family: 'Montserrat', sans-serif;*/
.info-descripcion{
  color:#01c7e2;
  font-size: 0.8em;
  /*font-family: 'Montserrat', sans-serif;*/
}
.info-video{
  color:#000000;
}
.info-horario{
  margin: 15px;
}
.entradas{
  background-image: url('../../images/01_impacDemo_home/04-fondo.jpg');
  border-image-repeat: no-repeat;
  background-size: cover;
}
.divEntradas{
  text-align: right;
  line-height: 1;
}
.tituEntradas{
  color:#eb4b1c;
  margin: 3% 0% 0% 0%;
  font-size: 3em;
}
.textEntradas{
  color:#000000;
  font-size: 1.5em;
}
.tituLogos{
  border-bottom: 4px solid #000000;
  font-size: 3em;
  text-align: left;
  color: #000000;
}
.slick-arrow, .slick-next, .slick-dots{
  display: none!important;
}
.slick-prev, .slick-next{
  display: none!important;
}
.btn-registrar{
  width: 200px;
}
.principal{
  height: 1300px;
}
@media only screen and (max-width: 2700px) {
  .principal{
    height: 2350px!important;
  }
  .btn-registrar{
    width: 500px;
  }

}
@media only screen and (max-width: 1700px) {
  .principal{
    height: 1350px!important;
  }
  .btn-registrar{
    width: 250px;
  }
}
@media only screen and (max-width: 1300px) {
  .principal{
    height: 1250px!important;
  }
  .btn-registrar{
    width: 200px;
  }
}
@media only screen and (max-width: 990px) {
  .principal{
    height: 900px!important;
  }
  .btn-registrar{
    width: 150px;
  }
  .fecha{
    font-size: 23px;
  }
  .numero{
    font-size: 25px;
  }
  .info-hora{
    font-size: 1.3em;
  }
  .info-fecha{
    font-size: 1.3em;
  }
  .info-nombre{
    font-size: 1.2em;
  }
  .info-descripcion{
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 700px) {
  .principal{
    height: 650px!important;
  }
  .fecha{
    font-size: 15px;
  }
  .numero{
    font-size: 22px;
  }
  .btn-registrar{
    width: 100px;
  }
  .info-hora{
    font-size: 1.1em;
  }
  .info-fecha{
    font-size: 1.1em;
  }
  .info-nombre{
    font-size: 1em;
  }
  .info-descripcion{
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 500px) {
  .principal{
    height: 500px!important;
  }
}
@media only screen and (max-width: 400px) {
  .principal{
    height: 500px!important;
  }
}



