.cuerpo{
  background-image: url('../../assets/images/eventos/01-ingenierIa-vehicular/03-fondo.jpg');
  border-image-repeat: repeat;
  background-size: cover;
}

.cuerpo_vehiculos{
  background-color: #ffffff;
}
.tipo_preguntas_select{
  color:#0d3159;
  background-color: #ffdc38;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}
.tipo_preguntas{
  color:#0d3159;
  background-color: white;
  padding-bottom: 0px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.texto-respuetas{
  font-size: 0.85em;
  text-align: left;
  color: black;
  height: auto;
  line-height: 1;
  border-radius: 10px;
}

.texto-preguntas{
  font-size: 0.85em;
  text-align: right;
  color: black;
  height: auto;
  line-height: 1;
  border-radius: 10px;
}
.hora-preguntas{
  font-size: 0.7em;
  text-align: right;
  margin: 0% 5%;
  padding: 0% 1% 1% 1%;
}

.ant-form-item{
  margin-bottom: 1%!important;
}
.btn-enviar{
  color: #ffffff!important;
  background-color: #3eb5af!important;
  border-radius: 6px!important;
  font-size: 0.8em!important;
  border-color: #000000!important;
  z-index: 99;
}
.ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
  color:#ffffff;
}
.sujeto{
  color: #ffffff;
  font-size: 10px;
  text-align: left;
}

.header-preguntas{
  background-color: #0d3159;
  color: #ffffff;
  font-size: 1.6em;
  padding-top: 0px;
  padding-bottom: 0px;
}
.pregunta-p{
  margin-bottom: 0px;
  padding: 10px 0px 10px 0px;
  text-align: center;
  letter-spacing: 4px;
  font-size: 20px;
}
.ver-preguntas{
  background-color: #ececec;
  height: 280px;
  overflow: scroll;
}
.ver-pregus{
  background-color: #ffffff;
  height: 350px;
  overflow: scroll;
  border: 2px solid #0d3159;
}


/*PONENTES ESTILOS*/
.nombre_ponente{
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #112f6d;
}
.texto_ponente{
  font-size: 15px;
  text-align: justify;
}
.titulo_ponente{
  color:#0d3159;
  font-weight: 900;
  text-align: center;
  font-size: 34px;
}
.nombre_ponente_2{
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  background-color: #0d3159;
  margin-bottom: 0px;
}
.link_ponente{
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #0d3159;
  background-color: #7ce6e0;
  margin-bottom: 0px;
}
.text_enviar{
  margin-left: 0px!important;
}
.area_encuesta{
  background-color: #eb4b1c;
  color: #ffffff;
  font-size: 2em;
  text-align: left;
  padding: 5px 0px 0px 5px;
}
.area_resultados{
  background-color: #000000;
  color: #ffffff;
  font-size: 2em;
  text-align: left;
  padding: 5px 0px 0px 5px;
}
.text-nom, .text-empre{
  font-size: 0.75em;
  line-height: 1;
}
.preguntas-header{
  background-color: #95ebf4!important;

}
.contenedor_video {
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
	padding-top: 0px;
	height: 0;
  z-index:99;
}
.contenedor_video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  z-index:99;
}

.contenedor_video img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video2 {
  height: 550px;
}
.tabs_preguntas{
  background-color: red;
}

.titulo_webinar_2{
  font-size: 1.4em;
  text-align: center;
  color: #0d3159;
  margin-bottom: 0px;
  letter-spacing: 2px;
  word-spacing: 4px;
}

.titulo_webinar{
  font-size: 1.7em;
  text-align: left;
  color: #0d3159;
  margin-bottom: 0px;
  letter-spacing: 2px;
  word-spacing: 4px;
}
.select_dia{
  background-color: #0d3159;
  color:#ffffff;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
}
.no_select_dia{
  background-color: #ffffff;
  color:#0d3159;
  font-size: 24px;
  text-align: center;
  border-color: #0d3159;
  cursor: pointer;

}
@media screen and (min-width:1301px){
  .titulo_webinar{
    font-size: 1.4em;
  }
}

@media screen and (min-width: 992px) and (max-width:1300px){
  .titulo_webinar{
    font-size: 1.0em;
  }
}

@media screen and (min-width: 768px) and (max-width:991px){
  .titulo_webinar{
    font-size: 1.2em;
    
  }
}

@media screen and (min-width: 576px) and (max-width:767px){
  .titulo_webinar{
    font-size: 1.0em;
    
  }
}

@media screen and (max-width: 575px){
  .titulo_webinar{
    font-size: 0.8em;
  }
}

.concoce_ponentes{
  color: #112f6d;
  font-size: 3em;
  text-align: left;
}
.contenedor_live{
  height: auto;
}
.contenedor_live_preguntas{
  /*height: 920px;*/
}
.clic_ver{
  color: black;
  text-align: left;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 14px;
}
.contenedor_aniversario, .contenedor_graficas{
  height: 300px;
}

@media only screen and (min-width: 991px) {
  .video{
    height: 500px;
  }
  .video2 {
    height: 500px;
  }

  .imagen_logo{
    height:500px;
  }
}
@media only screen and (max-width: 2600px) {
  .ver-preguntas{
    height: 640px;
  }
}
@media only screen and (max-width: 2500px) {
  .ver-preguntas{
    height: 600px;
  }
}
@media only screen and (max-width: 2400px) {
  .ver-preguntas{
    height: 570px;
  }
  .contenedor_live{
    height: 820px;
  }
  .contenedor_live_preguntas{
    height: 820px;
  }
}
@media only screen and (max-width: 2300px) {
  .ver-preguntas{
    height: 540px;
  }
}
@media only screen and (max-width: 2150px) {
  .ver-preguntas{
    height: 530px;
  }
}
@media only screen and (max-width: 2090px) {
  .ver-preguntas{
    height: 515px;
  }
}
@media only screen and (max-width: 2025px) {
  .ver-preguntas{
    height: 510px;
  }
}
@media only screen and (max-width: 2000px) {
  .ver-preguntas{
    height: 460px;
  }
  .contenedor_live{
    height: 750px;
  }
  .contenedor_live_preguntas{
    height: 750px;
  }
}
@media only screen and (max-width: 1940px) {
  .ver-preguntas{
    height: 470px;
  }
}
@media only screen and (max-width: 1780px) {
  .ver-preguntas{
    height: 450px;
  }
}
@media only screen and (max-width: 1700px) {
  .ver-preguntas{
    height: 405px;
  }
  .contenedor_live{
    height: 700px;
  }
  .contenedor_live_preguntas{
    height: 700px;
  }
}
@media only screen and (max-width: 1620px) {
  .ver-preguntas{
    height: 380px;
  }
}
@media only screen and (max-width: 1580px) {
  .ver-preguntas{
    height: 390px;
  }
}
@media only screen and (max-width: 1550px) {
  .ver-preguntas{
    height: 320px;
  }
  .contenedor_live{
    height: 570px;
  }
  .contenedor_live_preguntas{
    height: 570px;
  }
}
@media only screen and (max-width: 1441px) {
  .ver-preguntas{
    height: 290px;
  }
  .contenedor_live{
    height: 550px;
  }
  .contenedor_live_preguntas{
    height: 550px;
  }
}
@media only screen and (max-width: 1381px) {
  .ver-preguntas{
    height: 290px;
  }
}
@media only screen and (max-width: 1361px) {
  .ver-preguntas{
    height: 260px;
  }
  .contenedor_live{
    height: 520px;
  }
  .contenedor_live_preguntas{
    height: 520px;
  }
}
@media only screen and (max-width: 1300px) {
  .ver-preguntas{
    height: 290px;
  }
  .contenedor_live{
    height: 500px;
  }
  .contenedor_live_preguntas{
    height: 500px;
  }
}
@media only screen and (max-width: 1250px) {
  .ver-preguntas{
    height: 250px;
  }
  .contenedor_live{
    height: 480px;
  }
  .contenedor_live_preguntas{
    height: 480px;
  }
}
@media only screen and (max-width: 1190px) {
  .ver-preguntas{
    height: 235px;
  }
}
@media only screen and (max-width: 1161px) {
  .ver-preguntas{
    height: 220px;
  }
  .contenedor_live{
    height: 450px;
  }
  .contenedor_live_preguntas{
    height: 450px;
  }
  .header-preguntas{
    font-size: 1.4em;
  }
  .tipo_preguntas_select{
    font-size: 10px;
  }
  .tipo_preguntas{
    font-size: 10px;
  }
}
@media only screen and (max-width: 1090px) {
  .ver-preguntas{
    height: 210px;
  }
  .contenedor_live{
    height: 420px;
  }
  .contenedor_live_preguntas{
    height: 420px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
  .btn-enviar{
    font-size: 0.7em!important;
    padding: 2px 7px 2px 7px!important;
    z-index: 99;
  }
  .contenedor_aniversario, .contenedor_graficas{
    height: 280px;
  }
}
@media only screen and (max-width: 1020px) {
  .ver-preguntas{
    height: 190px;
  }
}
@media only screen and (max-width: 991px) {
  .video{
    height: 400px;
  }
  .video2 {
    height: 350px;
  }

  .imagen_logo{
    height:500px;
  }

  .ver-preguntas{
    height: 210px;
  }
  .contenedor_live{
    height: 540px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
  .btn-enviar{
    font-size: 0.8em!important;
    padding: 2px 7px 2px 7px!important;
    margin-bottom: 5px;
    z-index: 99;
  }
  .tipo_preguntas_select{
    font-size: 12px;
  }
  .tipo_preguntas{
    font-size: 12px;
  }
  .contenedor_aniversario{
    height: 320px;
  }
  .contenedor_graficas{
    height: 260px;
  }
}
@media only screen and (max-width: 900px) {

  .contenedor_live{
    height: 490px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
}
@media only screen and (max-width: 850px) {
  .contenedor_live{
    height: 470px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
}
@media only screen and (max-width: 800px) {
  .contenedor_live{
    height: 435px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
}
@media only screen and (max-width: 750px) {
  .ver-preguntas{
    height: 240px;
  }
  .contenedor_live{
    height: 410px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
}
@media only screen and (max-width: 700px) {
  .contenedor_aniversario{
    height: 300px;
  }
  .contenedor_graficas{
    height: 240px;
  }
  .contenedor_live{
    height: 390px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
}
@media only screen and (max-width: 650px) {
  .contenedor_live{
    height: 360px;
  }
  .contenedor_aniversario{
    height: 300px;
  }
  .contenedor_graficas{
    height: 240px;
  }
}
@media only screen and (max-width: 600px) {
  .video{
    height: 300px;
  }
  .video2{
    height: 250px;
  }

  .imagen_logo{
    height:250px;
  }

  .contenedor_live{
    height: 350px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
  .contenedor_aniversario{
    height: 280px;
  }
  .contenedor_graficas{
    height: 240px;
  }
}
@media only screen and (max-width: 550px) {
  .contenedor_live{
    height: 320px;
  }
  .contenedor_aniversario{
    height: 260px;
  }
}
@media only screen and (max-width: 500px) {
  .contenedor_live{
    height: 290px;
  }
}
@media only screen and (max-width: 450px) {
  .contenedor_live{
    height: 250px;
  }
  .clic_ver{
    font-size: 10px;
  }
  .contenedor_aniversario{
    height: 230px;
  }
}
@media only screen and (max-width: 400px) {
  .contenedor_live{
    height: 230px;
  }
  .contenedor_live_preguntas{
    height: 390px;
  }
  .ver-preguntas{
    height: 190px;
  }
  .contenedor_aniversario{
    height: 200px;
  }
}

.balloon{
  border-radius: 20px;
  background-color: white;
  color: black;
  padding: 10px;
  width: 90%;
  margin-left:5%;
  margin-top:20px;
  margin-bottom:20px;
  display: block;
  position:relative;

  font-size: 1em;
  text-align: right;
  height: auto;
  line-height: 1;
  }

  .balloon:before {
    content:"";
    width: 0;
    height: 0;
    position: absolute;
    bottom:30%;
    right:-18px;
    border-bottom: 10px solid transparent;
    border-right: 10px solid transparent;
    border-left: 15px solid white;
    border-top: 10px solid transparent;
    }

    .balloon_respuesta{
      border-radius: 20px;
      background-color: white;
      color: black;
      padding: 10px;
      width: 90%;
      margin-left:5%;
      margin-top:20px;
      margin-bottom:20px;
      display: block;
      position:relative;
    
      font-size: 1em;
      text-align: right;
      height: auto;
      line-height: 1;
      }
    
      .balloon_respuesta:before {
        content:"";
        width: 0;
        height: 0;
        position: absolute;
        bottom:30%;
        left:-20px;
        border-bottom: 10px solid transparent;
        border-right: 15px solid white;
        border-left: 10px solid transparent;
        border-top: 10px solid transparent;
        }

    .img_preguntas_sin_sesion{
      width:100%; 
      height:112px;
      cursor:pointer;
    }

    .contenedor_enviar{
      background-color: #0d3159;
      padding-top: 25px;
    }

    @media screen and (min-width:1301px){
      .img_preguntas_sin_sesion{
        height:102px;
      }

      .contenedor_enviar{
        background-color: #0d3159;
        padding-top: 25px;
      }
    }
    
    @media screen and (min-width: 992px) and (max-width:1300px){
      .img_preguntas_sin_sesion{
        height:95px;
      }
      .pregunta-p{
        font-size: 14px;
      }

      .contenedor_enviar{
        background-color: #0d3159;
        padding-top: 15px;
      }
    }
    
    @media screen and (min-width: 768px) and (max-width:991px){
      .img_preguntas_sin_sesion{
        height:112px;
      }

      .pregunta-p{
        font-size: 20px;
      }
    }

