.estilo_campos{
    color:white;
}

.contenedor_registro{
    
}

.contenedor_img_sin_login{
    text-align: center;
}

.img_iniciar_sin_login{
    width: 180px;
    cursor: pointer;
}
