.titles{
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;;
}
.seccion{
  padding: 2%;
}
StickyContainer{
  position: -webkit-sticky;
position: sticky;
top: 0;
}