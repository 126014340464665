.footer{
  background-color: black;
}
.text-blanco{
  color: #ffffff;
  text-align: left;
}
.redes{
  color: #eb4b1c;
}
.link-footer{
  color: #ffffff;
  text-decoration: none;
  text-align: left;
}
.titu-footer{
  color: #eb4b1c;
  font-size: 2.5em;
  text-align: left;
  font-family: 'Economica', sans-serif;
  margin-bottom: 10px;;
}